import React from 'react'
import slugify from 'slugify'
import Link from 'src/components/GatsbyLinkWrapper'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import * as URLs from '../../../../config/api/Urls'

import DynamicList from 'components/DynamicList'
import CallToAction from 'src/components/CallToAction'
import SectionTopics from 'src/components/SectionTopics'
import AdequacaoCvm from 'src/templates/adequacao-cvm'

import AnbimaBlack from 'src/assets/images/temps/fundos-de-investimentos/anbima-black.png'
import ComoInvestir from 'src/assets/images/temps/fundos-de-investimentos/como-investir.png'
import QualificacaoOperacional from 'src/assets/images/temps/fundos-de-investimentos/qualificacao-operacional.png'

import ListFundsIDTVM from 'src/assets/data/funds-idtvm/list-funds-idtvm.json'

import { ListFundsIdtvmProps } from 'src/components/DynamicList/types'
import { ContentWrapper, SideBar } from 'src/layouts/BaseLayout/style'
import { Logo } from './styles'

export const Content = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <ContentWrapper>
      <section className='pt-5 pb-lg-5 bg-white'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col col-lg-8'>
              <h3>Ainda mais opções para você investir</h3>
              <p>A Inter DTVM é a Distribuidora de Títulos e Valores Mobiliários do Banco Inter. Estruturada como uma
                plataforma aberta, permite ampliar <Link to='/pra-voce/investimentos/' className='fw-600'>possibilidades de investimentos</Link> para os nossos clientes:
              </p>
              <SectionTopics title='Vantagens' styles='fs-md-24'>
                <ul className='list-unstyled'>
                  <li>A melhor seleção de produtos</li>
                  <li>Gestão de recursos e atendimento personalizados</li>
                  <li>Transparência e comprometimento com os investidores</li>
                </ul>
              </SectionTopics>
              <h4 className='pt-3'>Produtos para cada perfil e para o seu momento</h4>
              <p>Nosso time de analistas especializados busca no Mercado de Capitais as melhores oportunidades de investimentos
                para atender a diferentes perfis de investidores e seus objetivos.
              </p>
              <p>
                <strong>Gestão de Fundos de Investimentos</strong><br />
                Fundos próprios e exclusivos/restritos.
              </p>
              <p>
                <strong>Distribuição</strong><br />
                Títulos Privados, Fundos de Investimentos de diversas classNamees, Previdência Privada.
              </p>
              <p>
                <strong>Custódia de Ações e Tesouro Direto</strong><br />
                Compra e Venda de Ações, Aluguel de Ações, Operações a Termo e Opções.
              </p>
              <DynamicList name='inter-dtvm' path='FILES' />
              <h4 className='mt-4 mb-5'>Fundos administrados</h4>
              {
                ListFundsIDTVM.map((document: ListFundsIdtvmProps, index: number) => (
                  <SectionTopics key={index} title={document.fundTitle} url={`/pra-voce/investimentos/fundos-de-investimento/${slugify(String(document.slug), { strict: true }).toLowerCase()}/`}>
                    <div dangerouslySetInnerHTML={{ __html: document.descriptionResume }} />
                    <a
                      href={`/pra-voce/investimentos/fundos-de-investimento/${slugify(String(document.slug), { strict: true }).toLowerCase()}/`}
                      className='link-arrow-right'
                      title={`Saiba mais sobre o fundo ${document.fundTitle}`}
                      onClick={() => {
                        sendDatalayerEvent({
                          section: 'dobra_02',
                          element_action: 'click button',
                          element_name: `Saiba mais - ${document.fundTitle}`,
                          section_name: 'Fundos administrados',
                          redirect_url: URLs.SITE_BASE_URL + `/pra-voce/investimentos/fundos-de-investimento/${slugify(String(document.slug), { strict: true }).toLowerCase()}/`,
                        })
                      }}
                    >
                      Saiba mais
                    </a>
                  </SectionTopics>
                ))
              }
              <h4 className='pt-4'>Taxas de corretagem para operações via mesa</h4>
              <table className='table table-striped mb-3'>
                <thead>
                  <tr>
                    <th>Faixa</th>
                    <th>De</th>
                    <th>Até</th>
                    <th>Custo Variável</th>
                    <th>Custo Fixo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td data-label='Faixa'>1</td>
                    <td data-label='De'>R$ -</td>
                    <td data-label='Até'>R$ 135,05</td>
                    <td data-label='Custo Variável'>0,00%</td>
                    <td data-label='Custo Fixo'>R$ 2,70</td>
                  </tr>
                  <tr>
                    <td data-label='Faixa'>2</td>
                    <td data-label='De'>R$ 135,06</td>
                    <td data-label='Até'>R$ 498,62</td>
                    <td data-label='Custo Variável'>2,00%</td>
                    <td data-label='Custo Fixo'>R$ -</td>
                  </tr>
                  <tr>
                    <td data-label='Faixa'>3</td>
                    <td data-label='De'>R$ 498,63</td>
                    <td data-label='Até'>R$ 1514,69</td>
                    <td data-label='Custo Variável'>1,50%</td>
                    <td data-label='Custo Fixo'>R$ 2,49</td>
                  </tr>
                  <tr>
                    <td data-label='Faixa'>4</td>
                    <td data-label='De'>R$ 1514,68</td>
                    <td data-label='Até'>R$ 3029,38</td>
                    <td data-label='Custo Variável'>1,00%</td>
                    <td data-label='Custo Fixo'>R$ 10,06</td>
                  </tr>
                  <tr>
                    <td data-label='Faixa'>5</td>
                    <td data-label='De'>R$ 3029,39</td>
                    <td data-label='Até'>R$ 10.000.000,00</td>
                    <td data-label='Custo Variável'>0,50%</td>
                    <td data-label='Custo Fixo'>R$ 25,21</td>
                  </tr>
                </tbody>
              </table>
              <p className='mb-5'>
                Taxa mínima de corretagem para operações via mesa: R$ 50,00.
              </p>
              <h4>Links úteis</h4>
              <ul className='list-unstyled pb-3'>
                <li>
                  <a
                    href='https://www.cvm.gov.br/'
                    rel='noreferrer'
                    target='_blank' title='CVM'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_03',
                        element_action: 'click button',
                        element_name: 'CVM',
                        section_name: 'Links úteis',
                        redirect_url: 'https://www.cvm.gov.br/',
                      })
                    }}
                  >CVM
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.b3.com.br/pt_br/'
                    rel='noreferrer'
                    target='_blank' title='B3'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_03',
                        element_action: 'click button',
                        element_name: 'B3',
                        section_name: 'Links úteis',
                        redirect_url: 'https://www.b3.com.br/pt_br/',
                      })
                    }}
                  >B3
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.bsmsupervisao.com.br/'
                    rel='noreferrer' target='_blank'
                    title='BSM'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_03',
                        element_action: 'click button',
                        element_name: 'BSM',
                        section_name: 'Links úteis',
                        redirect_url: 'https://www.bsmsupervisao.com.br/',
                      })
                    }}
                  >BSM
                  </a>
                </li>
                <li>
                  <Link
                    to='/inter-dtvm/tipos-de-risco/'
                    title='Tipos de risco'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_03',
                        element_action: 'click button',
                        element_name: 'BSM',
                        section_name: 'Links úteis',
                        redirect_url: URLs.SITE_BASE_URL + '/inter-dtvm/tipos-de-risco/',
                      })
                    }}
                  >Tipos de risco
                  </Link>
                </li>
                <li>
                  <Link
                    to='/inter-dtvm/mecanismo-ressarcimento-prejuizos/'
                    title='Mecanismo de Ressarcimento de Prejuízos'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_03',
                        element_action: 'click button',
                        element_name: 'Mecanismo de Ressarcimento de Prejuízos',
                        section_name: 'Links úteis',
                        redirect_url: URLs.SITE_BASE_URL + '/inter-dtvm/mecanismo-ressarcimento-prejuizos/',
                      })
                    }}
                  >
                    Mecanismo de Ressarcimento de Prejuízos
                  </Link>
                </li>
                <li>
                  <Link
                    to='/inter-dtvm/tipos-mercados-organizados/'
                    title='Tipos de mercados organizados'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_03',
                        element_action: 'click button',
                        element_name: 'Tipos de mercados organizados',
                        section_name: 'Links úteis',
                        redirect_url: URLs.SITE_BASE_URL + '/inter-dtvm/tipos-mercados-organizados/',
                      })
                    }}
                  >
                    Tipos de mercados organizados
                  </Link>
                </li>
                <li>
                  <a
                    href='https://inter.co/politica-de-seguranca/?_ga=2.136462708.570745000.1649679203-369842006.1646238853#:~:text=O%20Grupo%20Inter%20pauta%20suas,CIS%20%E2%80%93%20Center%20for%20Internet%20Security'
                    rel='noreferrer' target='_blank'
                    title='Política de Segurança da Informação e Segurança Cibernética'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_03',
                        element_action: 'click button',
                        element_name: 'Política de Segurança da Informação e Segurança Cibernética',
                        section_name: 'Links úteis',
                        redirect_url: 'https://inter.co/politica-de-seguranca/?_ga=2.136462708.570745000.1649679203-369842006.1646238853#:~:text=O%20Grupo%20Inter%20pauta%20suas,CIS%20%E2%80%93%20Center%20for%20Internet%20Security',
                      })
                    }}
                  >
                    Política de Segurança da Informação e Segurança Cibernética
                  </a>
                </li>
                <li>
                  <Link
                    to='/pra-voce/investimentos/assessoria-investimentos/'
                    title='Relação de Assessores de Investimento'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_03',
                        element_action: 'click button',
                        element_name: 'Relação de Assessores de Investimento',
                        section_name: 'Links úteis',
                        redirect_url: '/pra-voce/investimentos/assessoria-investimentos/',
                      })
                    }}
                  >
                    Relação de Assessores de Investimento
                  </Link>
                </li>
              </ul>
              <div className='row pb-5 pt-3'>
                <div className='col-6 col-sm-3 col-md-3 col-lg-3 mb-3'>
                  <a
                    href='https://comoinvestir.anbima.com.br/'
                    rel='noreferrer' target='_blank'
                    title='Como investir'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_03',
                        element_action: 'click button',
                        element_name: 'Logo Como investir',
                        section_name: 'Links úteis',
                        redirect_url: 'https://comoinvestir.anbima.com.br/',
                      })
                    }}
                  >
                    <Logo src={ComoInvestir} alt='Como investir' />
                  </a>
                </div>
                <div className='col-6 col-sm-3 col-md-3 col-lg-3 mb-3'>
                  <a
                    href='https://www.anbima.com.br/pt_br/pagina-inicial.htm'
                    rel='noreferrer' target='_blank'
                    title='Anbima'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_03',
                        element_action: 'click button',
                        element_name: 'Logo Anbima',
                        section_name: 'Links úteis',
                        redirect_url: 'https://www.anbima.com.br/pt_br/pagina-inicial.htm',
                      })
                    }}
                  >
                    <Logo src={AnbimaBlack} alt='Anbima' />
                  </a>
                </div>
                <div className='col-6 col-sm-3 col-md-3 col-lg-3 mb-3'>
                  <Logo src={QualificacaoOperacional} alt='Anbima Administração Fiduciária' />
                </div>
              </div>
              <AdequacaoCvm />
            </div>
          </div>
        </div>
      </section>
      <div className='container pb-4'>
        <SideBar className='col-12 col-lg-4 px-0 px-lg-3'>
          <div className='position-sticky'>
            <CallToAction
              section='dobra_2'
              elementAction='click button'
              elementName='Abra sua conta'
              sectionName='Ainda mais opções para você investir'
              redirectUrl='/pra-voce/conta-digital/pessoa-fisica/?&modal=open'
              text='Abra sua conta'
              chat={true}
              link='/pra-voce/conta-digital/pessoa-fisica/?&modal=open'
            />
          </div>
        </SideBar>
      </div>
    </ContentWrapper>
  )
}
